
import { defineComponent, ref, onMounted, reactive } from 'vue';
import { ElConfigProvider } from 'element-plus';
import useEmitter from '@/composables/Emmiter'
import { useStore } from 'vuex';
import { Actions } from "@/store/enums/StoreEnums";
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import { ConcessionariaModel } from '@/models/ConcessionariaModel';

interface ConcessionariasOptions {
    text: string,
    value: string,
}

export default defineComponent({
    name: "filtro-arquivos-exportados",
    components: {
        FilterBase,
        ElConfigProvider,
    },
    setup(props, { emit }) {
        const store = useStore();
        const valorInicialCodConcessionaria = ref('');
        if (window.localStorage.getItem("id_usuario")) {
            valorInicialCodConcessionaria.value = window.localStorage.getItem("filtroConcessionaria") ?? "";
        } else {
            valorInicialCodConcessionaria.value = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
        }
        const codConcessionariaSelected = ref(valorInicialCodConcessionaria ?? "");
        const emitter = useEmitter();
        const activeModal = ref(false);
        const closeModal = ref(false);
        const disabledClick = ref(false);
        const showClose = ref(false)
        const optionsConcessionaria = ref<ConcessionariasOptions[]>([]);
        const label = ref("Faça filtros por concessionária");
        const showGoToBack = ref(false);
        const loading = reactive({
            concessionaria: true,
        });

        store.watch(() => store.getters.concessionariasList, () => {
            trataConcessionarias();
        }, { deep: true });

        onMounted(() => {
            if (store.getters.concessionariasList.length > 0) {
                trataConcessionarias();
            }
        });

        async function trataConcessionarias() {
            const concessionarias = store.getters.concessionariasList;

            optionsConcessionaria.value = concessionarias.map(concessionaria => {
                return {
                    text: concessionaria.nomeFantasia,
                    value: concessionaria.codConcessionaria,
                }
            }).sort((concessionariaA, concessionariaB) => {

                if (concessionariaA.text < concessionariaB.text) {
                    return -1;
                }
                if (concessionariaA.text > concessionariaB.text) {
                    return 1;
                }
                return 0;
            });


            loading.concessionaria = false
        };

        async function emiteMudanca(): Promise<void> {

            window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value)
            store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);

            emitter.emit('filter-arquivos-exportados', {
                codConcessionaria: codConcessionariaSelected.value,
            })

            closeModal.value = !closeModal.value;
        };

        return {
            activeModal,
            closeModal,
            showClose,
            emitter,
            emiteMudanca,
            optionsConcessionaria,
            codConcessionariaSelected,
            showGoToBack,
            disabledClick,
            loading,
            label
        };
    }
});
